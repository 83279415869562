import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.GRANT_SERVER_PREFIX}/bill-submission`;
const endpoints = {
    list: () => `${RESOURCE_ENDPOINT}`,
    bstftList: () => `${RESOURCE_ENDPOINT}/bstft-bill-list`,
    commonList: () => `${RESOURCE_ENDPOINT}/common-bill-list`,
    getById: (id: any) => `${RESOURCE_ENDPOINT}/${id}`,
    getForeignUniversityBillByFinalSelectionId: (finalSelectionId: any, billId: any) => `${RESOURCE_ENDPOINT}/foreign-university-previous-bill-by-final-selection-id/${finalSelectionId}/${billId}`,
    getPreviousBill: (finalSelectionId: any, billId: any) => `${RESOURCE_ENDPOINT}/previous-bill/${finalSelectionId}/${billId}`,
    getByWhere: () => `${RESOURCE_ENDPOINT}/getByWhere`,
    create: () => `${RESOURCE_ENDPOINT}`,
    createBackendBill: () => `${RESOURCE_ENDPOINT}/backend-bill`,
    sendBillToIbas: () => `${RESOURCE_ENDPOINT}/send-bill-to-ibas`,
    createForeignUniversityBill: () => `${RESOURCE_ENDPOINT}/store-foreign-university-bill`,
    update: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    updateBackendBill: (id: Number) => `${RESOURCE_ENDPOINT}/backend-bill/${id}`,
    updateForeignUniversityBill: (id: Number) => `${RESOURCE_ENDPOINT}/update-foreign-university-bill/${id}`,
    updatePartial: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    reviewBill: (id: Number) => `${RESOURCE_ENDPOINT}/review-bill/${id}`,
    sendMasterDataToIbas: (id: Number) => `${RESOURCE_ENDPOINT}/send-master-data-to-ibas/${id}`,
    delete: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    bulk: () => `${RESOURCE_ENDPOINT}/bulk`,
    dropdown: () => `${RESOURCE_ENDPOINT}/dropdown`,
    getSRGTotalBill: () => `${RESOURCE_ENDPOINT}/getSRG-bill`,
    sendReviewedBillMasterDatatoIbas: () => `${RESOURCE_ENDPOINT}/send-reviewed-bill-master-data-to-ibas`,
    sendBillPaymentDatatoIbas: () => `${RESOURCE_ENDPOINT}/send-payment-data-to-ibas-for-validated-master-data`,
};

export default class BillSubmissionApi {
    public list = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.list();

        return HttpService.get(url, params, headers);
    };
    public bstftList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.bstftList();
        return HttpService.get(url, params, headers);
    };

    public commonList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.commonList();
        return HttpService.get(url, params, headers);
    };

    public getById = (id: any): AxiosPromise<any> => {
        const url = endpoints.getById(id);
        return HttpService.get(url);
    };

    public getForeignUniversityBillByFinalSelectionId = (finalSelectionId: any, billId?:any): AxiosPromise<any> => {
        const url = endpoints.getForeignUniversityBillByFinalSelectionId(finalSelectionId, billId);
        return HttpService.get(url);
    };
    public getPreviousBill = (finalSelectionId: any, billId?:any): AxiosPromise<any> => {
        const url = endpoints.getPreviousBill(finalSelectionId, billId);
        return HttpService.get(url);
    };

    public getByWhere = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getByWhere();
        return HttpService.get(url, params, headers);
    }

    public create = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.create();
        return HttpService.post(url, payload, params, headers);
    };
    public createBackendBill = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.createBackendBill();
        return HttpService.post(url, payload, params, headers);
    };

    public getSRGTotalBill = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getSRGTotalBill();
        return HttpService.post(url, payload, params, headers);
    }

    public sendBillToIbas = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.sendBillToIbas();
        return HttpService.post(url, payload, params, headers);
    };

    public createForeignUniversityBill = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.createForeignUniversityBill();
        return HttpService.post(url, payload, params, headers);
    };

    public update = (
        id: any,
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.update(id);
        return HttpService.put(url, payload, params, headers);
    };
    public updateBackendBill = (
        id: any,
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.updateBackendBill(id);
        return HttpService.put(url, payload, params, headers);
    };

    public updateForeignUniversityBill = (
        id: any,
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.updateForeignUniversityBill(id);
        return HttpService.put(url, payload, params, headers);
    };

    public updatePartial = (
        id: any,
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.updatePartial(id);
        return HttpService.patch(url, payload, params, headers);
    };

    public reviewBill = (
        id: any,
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.reviewBill(id);
        return HttpService.post(url, payload, params, headers);
    };
    public sendMasterDataToIbas = (
        id: any,
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.sendMasterDataToIbas(id);
        return HttpService.post(url, payload, params, headers);
    };

    public delete = (id: any, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.delete(id);
        return HttpService.delete(url, params, headers);
    };

    public bulk = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.bulk();
        return HttpService.post(url, payload, params, headers);
    };

    public sendReviewedBillMasterDatatoIbas = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.sendReviewedBillMasterDatatoIbas();
        return HttpService.post(url, payload, params, headers);
    };
    public sendBillPaymentDatatoIbas = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.sendBillPaymentDatatoIbas();
        return HttpService.post(url, payload, params, headers);
    };

    public dropdown = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.dropdown();
        return HttpService.get(url, params, headers);
    };
}

// CDN Server
import _FileApi from "./Cdn/File.api";
import _CsvApi from "./Cdn/Csv.api";
import _ExcelApi from "./Cdn/Excel.api";
import _PdfApi from "./Cdn/Pdf.api";

// Auth Server
import _GroupApi from "./Auth/Group.api";
import _OauthApi from "./Auth/Oauth.api";
import _PermissionApi from "./Auth/Permission.api";
import _ResourceApi from "./Auth/Resource.api";
import _RoleApi from "./Auth/Role.api";
import _ScopeApi from "./Auth/Scope.api";
import _UserApi from "./Auth/User.api";
import _WorkspaceApi from "./Auth/Workspace.api";
import _OrganizationApi from "./Auth/Organization.api";
import _OrganogramApi from "./Auth/Organogram.api";
import _EmployeeApi from "./Auth/Employee.api";
import _RequestAccessApi from "./Auth/RequestAccess.api";
import _ApplicantProfileApi from "./Auth/ApplicantProfile.api";
import _InstituteHeadApi from "./Auth/InstituteHead.api";
import _DesignationApi from "./Auth/Designation.api";

// Core Server
import _LookupApi from "./Core/Lookup.api";
import _LocationApi from "./Core/Location.api";
import _PostApi from "./Core/Post.api";
import _OptionApi from "./Core/Option.api";
import _TemplateApi from "./Core/Template.api";
import _HelpApi from "./Core/Help.api";
import _ExportScmApi from "./Core/ExportScm.api";
import _ImportScmApi from "./Core/ImportScm.api";
import _FackApi from "./Core/Fack.api";
import _OrganizationCategoryApi from "./Core/OrganizationCategory.api";
import _DepartmentApi from "./Core/Department.api";
import _ApiResourceApi from "./Core/ApiResource.api";
import _FinancialYearApi from "./Core/FinancialYear.api";
import _EnumApi from "./Core/Enum.api";
import _BankApi from "./Core/Bank.api";
import _BankBranchApi from "./Core/BankBranch.api";
import _DepartmentSubjectApi from "./Core/DepartmentSubject.api";
import _FacultySchoolApi from "./Core/FacultySchool.api";
import _InstituteOrganizationApi from "./Core/InstituteOrganization.api";
import _AutomationRuleApi from "./Core/AutomationRule.api";
import _WorkflowApprovalApi from "./Core/WorkflowApproval.api";
import _WorkflowStepApi from "./Core/WorkflowStep.api";
import _WorkflowBulkTransitionApi from "./Core/WorkflowBulkTransition.api";
import _ServiceMappingApi from "./Core/ServiceMapping.api";
import _ServiceAssignmentApi from "./Core/ServiceAssignment.api";
import _ComponentApi from "./Core/Component.api";
import _ApplicantProfileEducationalQualificationApi from "./Core/ApplicantProfileEducationalQualification.api";
import _ApplicantProfilePreviousExperienceApi from "./Core/ApplicantProfilePreviousExperience.api";
import _ApplicantProfileAwardsScholarshipApi from "./Core/ApplicantProfileAwardsScholarship.api";
import _ApplicantProfilePublicationApi from "./Core/ApplicantProfilePublication.api";
import _ApplicantProfileTrainingSeminarCourseApi from "./Core/ApplicantProfileTrainingSeminarCourse.api";
import _ComponentAssignmentApi from "./Core/ComponentAssignment.api";
import _OrganizationBankAccountApi from "./Core/OrganizationBankAccount.api";
import _OwnerShipTypeApi from "./Core/OwnerShipType.api";
import _ExamApi from "./Core/Exam.api";
import _PaymentApi from "./Core/Payment.api";
import _PaymentHistoryApi from "./Core/PaymentHistory.api";
import _PaymentEchallanApi from "./Core/PaymentEchallan.api";
import _EducationalInstituteApi from "./Core/EducationalInstitute.api";

// Grant Server
import _FellowshipTypeApi from "./Grant/FellowshipType.api";
import _FellowshipAllowanceHeadApi from "./Grant/FellowshipAllowanceHead.api";
import _FellowshipAllowanceRateApi from "./Grant/FellowshipAllowanceRate.api";
import _FellowshipGroupApi from "./Grant/FellowshipGroup.api";
import _StudyTypeNameApi from "./Grant/StudyTypeName.api";
import _CircularApi from "./Grant/Circular.api";
import _EligibleCriteriaApi from "./Grant/EligibleCriteria.api";
import _SelectionCriteriaApi from "./Grant/SelectionCriteria.api";
import _ApplicationTypeApi from "./Grant/ApplicationType.api";
import _ItemTypeApi from "./Grant/ItemType.api";
import _StudyTypeApi from "./Grant/StudyType.api";
import _AttachmentTypeApi from "./Core/AttachmentType.api";
import _ApplicationFeeApi from "./Grant/ApplicationFee.api";
import _MarkDistributionApi from "./Grant/MarkDistribution.api";
import _CommitteeApi from "./Grant/Committee.api";
import _CommitteeMemberInfoApi from "./Grant/CommitteeMemberInfo.api";
import _FellowshipCategoryApi from "./Grant/FellowshipCategory.api";
import _PriorityProjectApi from "./Grant/PriorityProject.api";
import _SpecialAllocationRateApi from "./Grant/SpecialAllocationRate.api";
import _RAndDProjectApi from "./Grant/RAndDProject.api";
import _InternshipThesisApplicationApi from "./Grant/InternshipThesisApplication.api";
import _LagashoiProzuktiApi from "./Grant/LagashoiProzukti.api";
import _SpecialResearchGrantApplicationApi from "./Grant/SpecialResearchGrantApplication.api";
import _ExportGrantApi from "./Grant/ExportGrant.api";
import _ImportGrantApi from "./Grant/ImportGrant.api";
import _ReportGrantApi from "./Grant/ReportGrant.api";
import _FinalSelectionApi from "./Grant/FinalSelection.api";
import _BudgetPreparationApi from "./Grant/BudgetPreparation.api";
import _BudgetAllocationApi from "./Grant/BudgetAllocation.api";
import _BudgetDisbursementApi from "./Grant/BudgetDisbursement.api";
import _GOPublishApi from "./Grant/GOPublish.api";
import _ServiceWiseBillAndProgressMapApi from "./Grant/ServiceWiseBillAndProgressMap.api";
import _ResearcherProfileApi from "./Grant/ResearcherProfile.api";
import _AIApprovalConsentApi from "./Grant/AIApprovalConsent.api";
import _BillCirculationApi from "./Grant/BillCirculation.api";
import _BillSubmissionApi from "./Grant/BillSubmission.api";
import _ProgressReportApi from "./Grant/ProgressReport.api";
import _ApplicationApi from "./Grant/Application.api";
import _BSTFTApplicationApi from "./Grant/BSTFTApplication.api";
import _NSTFellowshipApplicationApi from "./Grant/NSTFellowshipApplication.api";
import _CommitteeTypeApi from "./Grant/CommitteeType.api";
import _ResearchCategoryAreaApi from "./Grant/ResearchCategoryArea.api";
import _ScientificOrgApi from "./Grant/ScientificOrg.api";
import _NonGovtInstituteApplicationApi from "./Grant/NonGovtInstituteApplication.api";
import _RAndDMOSTApi from "./Grant/RAndDMOST.api";
import _ResearchFellowshipApplicationApi from "./Grant/ResearchFellowshipApplication.api";
import _SynopsisOfResearchProjectApi from "./Grant/SynopsisOfResearchProject.api";
import _SynopsisOfResearchProjectRnDMoSTApi from "./Grant/SynopsisOfResearchProjectRnDMoST.api";
import _PreviousApprovedProjectApi from "./Grant/PreviousApprovedProject.api";
import _RnDPreviousProjectApi from "./Grant/RnDPreviousProject.api";
import _ApplicationMarkConsentApi from "./Grant/ApplicationMarkConsent.api";
import _ApplicantResultMarkPolicyApi from "./Grant/ApplicantResultMarkPolicy.api";
import _ApplicantMarkWiseAllocationApi from "./Grant/ApplicantMarkWiseAllocation.api";
import _AIResearcherChangeRequestApi from "./Grant/AIResearcherChangeRequest.api";
import _BankAdviceLetterTemplateApi from "./Grant/BankAdviceLetterTemplate.api";
import _BankAdviceLetterApi from "./Grant/BankAdviceLetter.api";
import _MeetingMinutesOfCommitteeApi from "./Grant/MeetingMinutesOfCommittee.api";
import _FellowshipCancellationApi from "./Grant/FellowshipCancellation.api";
import _AgreementSignApi from "./Grant/AgreementSign.api";
import _SponsorshipLetterApi from "./Grant/SponsorshipLetter.api";
import _TMFormApi from "./Grant/TMForm.api";
import _WorkflowTransitionApi from "./Grant/WorkflowTransitionGrant.api";
import _GrandAdminDashboardApi from "./Grant/GrandAdminDashboard.api";
import _AdmitCardApi from "./Grant/AdmitCard.api";
import _SRGReportReceiveApi from "./Grant/SRGReportReceive.api";
import _IbasAPIRequestLogApi from "./Grant/IbasAPIRequestLog.api";


// CMS Server
import _PageApi from "./Cms/Page.api";
import _BlocksApi from "./Cms/Blocks.api";
import _MenuApi from "./Cms/Menu.api";
import _ServicesApi from "./Cms/Services.api";
import _MediaApi from "./Cms/Media.api";
import _CategoriesApi from "./Cms/Categories.api";
import _WorkflowTransitionCoreApi from "./Core/WorkflowTransition.api";

import _ActivityLogApi from "./Util/ActivityLog.api";
import _AuditLogApi from "./Util/AuditLog.api";
import _WebsocketApi from "./Util/Websocket.api";

// Util
import _NotificationApi from "./Util/Notification.api";
import _MessageQueueApi from "./Util/MessageQueue.api";
import _MessagingApi from "./Util/Messaging.api";
import _SendMessageApi from "./Util/SendMessage.api";

// CND Server
export const FileApi = new _FileApi();
export const CsvApi = new _CsvApi();
export const ExcelApi = new _ExcelApi();
export const PdfApi = new _PdfApi();

// Auth Server
export const GroupApi = new _GroupApi();
export const OauthApi = new _OauthApi();
export const PermissionApi = new _PermissionApi();
export const ResourceApi = new _ResourceApi();
export const RoleApi = new _RoleApi();
export const ScopeApi = new _ScopeApi();
export const UserApi = new _UserApi();
export const WorkspaceApi = new _WorkspaceApi();
export const OrganizationApi = new _OrganizationApi();
export const ServiceApi = new _ServicesApi();
export const OrganogramApi = new _OrganogramApi();
export const EmployeeApi = new _EmployeeApi();
export const RequestAccessApi = new _RequestAccessApi();
export const ApplicantProfileApi = new _ApplicantProfileApi();
export const InstituteHeadApi = new _InstituteHeadApi();

// Core Server
export const FackApi = new _FackApi();
export const LookupApi = new _LookupApi();
export const LocationApi = new _LocationApi();
export const PostApi = new _PostApi();
export const OptionApi = new _OptionApi();
export const TemplateApi = new _TemplateApi();
export const HelpApi = new _HelpApi();
export const ExportScmApi = new _ExportScmApi();
export const ImportScmApi = new _ImportScmApi();
export const OrganizationCategoryApi = new _OrganizationCategoryApi();
export const DepartmentApi = new _DepartmentApi();
export const ApiResourceApi = new _ApiResourceApi();
export const FinancialYearApi = new _FinancialYearApi();
export const EnumApi = new _EnumApi();
export const BankApi = new _BankApi();
export const BankBranchApi = new _BankBranchApi();
export const DesignationApi = new _DesignationApi();
export const DepartmentSubjectApi = new _DepartmentSubjectApi();
export const FacultySchoolApi = new _FacultySchoolApi();
export const InstituteOrganizationApi = new _InstituteOrganizationApi();
export const AutomationRuleApi = new _AutomationRuleApi();
export const WorkflowApprovalApi = new _WorkflowApprovalApi();
export const WorkflowStepApi = new _WorkflowStepApi();
export const WorkflowTransitionApi = new _WorkflowTransitionApi();
export const GrandAdminDashboardApi = new _GrandAdminDashboardApi();
export const WorkflowTransitionCoreApi = new _WorkflowTransitionCoreApi();
export const WorkflowBulkTransitionApi = new _WorkflowBulkTransitionApi();
export const ServiceMappingApi = new _ServiceMappingApi();
export const ServiceAssignmentApi = new _ServiceAssignmentApi();
export const ComponentApi = new _ComponentApi();
export const ApplicantProfileEducationalQualificationApi = new _ApplicantProfileEducationalQualificationApi();
export const ApplicantProfilePreviousExperienceApi = new _ApplicantProfilePreviousExperienceApi();
export const ApplicantProfileAwardsScholarshipApi = new _ApplicantProfileAwardsScholarshipApi();
export const ApplicantProfilePublicationApi = new _ApplicantProfilePublicationApi();
export const ApplicantProfileTrainingSeminarCourseApi = new _ApplicantProfileTrainingSeminarCourseApi();
export const ComponentAssignmentApi = new _ComponentAssignmentApi();
export const OrganizationBankAccountApi = new _OrganizationBankAccountApi();
export const OwnerShipTypeApi = new _OwnerShipTypeApi();
export const ExamApi = new _ExamApi();
export const PaymentApi = new _PaymentApi();
export const PaymentHistoryApi = new _PaymentHistoryApi();
export const PaymentEchallanApi = new _PaymentEchallanApi();
export const EducationalInstituteApi = new _EducationalInstituteApi();


// Util
export const NotificationApi = new _NotificationApi();
export const MessageQueueApi = new _MessageQueueApi();
export const MessagingApi = new _MessagingApi();

//Grant Server
export const FellowshipTypeApi = new _FellowshipTypeApi();
export const FellowshipAllowanceHeadApi = new _FellowshipAllowanceHeadApi();
export const FellowshipAllowanceRateApi = new _FellowshipAllowanceRateApi();
export const FellowshipGroupApi = new _FellowshipGroupApi();
export const StudyTypeNameApi = new _StudyTypeNameApi();
export const CircularApi = new _CircularApi();
export const EligibleCriteriaApi = new _EligibleCriteriaApi();
export const SelectionCriteriaApi = new _SelectionCriteriaApi();
export const ApplicationTypeApi = new _ApplicationTypeApi();
export const ItemTypeApi = new _ItemTypeApi();
export const StudyTypeApi = new _StudyTypeApi();
export const AttachmentTypeApi = new _AttachmentTypeApi();
export const MarkDistributionApi = new _MarkDistributionApi();
export const CommitteeApi = new _CommitteeApi();
export const CommitteeMemberInfoApi = new _CommitteeMemberInfoApi();
export const FellowshipCategoryApi = new _FellowshipCategoryApi();
export const PriorityProjectApi = new _PriorityProjectApi();
export const SpecialAllocationRateApi = new _SpecialAllocationRateApi();
export const ApplicationFeeApi = new _ApplicationFeeApi();
export const SpecialResearchGrantApplicationApi = new _SpecialResearchGrantApplicationApi();
export const RAndDProjectApi = new _RAndDProjectApi();
export const InternshipThesisApplicationApi = new _InternshipThesisApplicationApi();
export const LagashoiProzuktiApi = new _LagashoiProzuktiApi();
export const ApplicationApi = new _ApplicationApi();
export const BSTFTApplicationApi = new _BSTFTApplicationApi();
export const NSTFellowshipApplicationApi = new _NSTFellowshipApplicationApi();
export const ServiceWiseBillAndProgressMapApi = new _ServiceWiseBillAndProgressMapApi();
export const CommitteeTypeApi = new _CommitteeTypeApi();
export const ResearchCategoryAreaApi = new _ResearchCategoryAreaApi();
export const ScientificOrgApi = new _ScientificOrgApi();
export const NonGovtInstituteApplicationApi = new _NonGovtInstituteApplicationApi();
export const RAndDMOSTApi = new _RAndDMOSTApi();
export const ResearchFellowshipApplicationApi = new _ResearchFellowshipApplicationApi();
export const ExportGrantApi = new _ExportGrantApi();
export const ImportGrantApi = new _ImportGrantApi();
export const ReportGrantApi = new _ReportGrantApi();
export const FinalSelectionApi = new _FinalSelectionApi();
export const BudgetPreparationApi = new _BudgetPreparationApi();
export const BudgetAllocationApi = new _BudgetAllocationApi();
export const BudgetDisbursementApi = new _BudgetDisbursementApi();
export const GOPublishApi = new _GOPublishApi();
export const ResearcherProfileApi = new _ResearcherProfileApi();
export const AIApprovalConsentApi = new _AIApprovalConsentApi();
export const SynopsisOfResearchProjectApi = new _SynopsisOfResearchProjectApi();
export const SynopsisOfResearchProjectRnDMoSTApi = new _SynopsisOfResearchProjectRnDMoSTApi();
export const PreviousApprovedProjectApi = new _PreviousApprovedProjectApi();
export const RnDPreviousProjectApi = new _RnDPreviousProjectApi();
export const BillCirculationApi = new _BillCirculationApi();
export const BillSubmissionApi = new _BillSubmissionApi();
export const ProgressReportApi = new _ProgressReportApi();
export const ApplicationMarkConsentApi = new _ApplicationMarkConsentApi();
export const ApplicantResultMarkPolicyApi = new _ApplicantResultMarkPolicyApi();
export const ApplicantMarkWiseAllocationApi = new _ApplicantMarkWiseAllocationApi();
export const AIResearcherChangeRequestApi = new _AIResearcherChangeRequestApi();
export const BankAdviceLetterTemplateApi = new _BankAdviceLetterTemplateApi();
export const BankAdviceLetterApi = new _BankAdviceLetterApi();
export const MeetingMinutesOfCommitteeApi = new _MeetingMinutesOfCommitteeApi();
export const FellowshipCancellationApi = new _FellowshipCancellationApi();
export const AgreementSignApi = new _AgreementSignApi();
export const SponsorshipLetterApi = new _SponsorshipLetterApi();
export const TMFormApi = new _TMFormApi();
export const AdmitCardApi = new _AdmitCardApi();
export const SRGReportReceiveApi = new _SRGReportReceiveApi();
export const IbasAPIRequestLogApi = new _IbasAPIRequestLogApi();

// CMS Server
export const PageApi = new _PageApi();
export const BlocksApi = new _BlocksApi();
export const MenuApi = new _MenuApi();
export const ServicesApi = new _ServicesApi();
export const MediaApi = new _MediaApi();
export const CategoriesApi = new _CategoriesApi();

// Util
export const ActivityLogApi = new _ActivityLogApi();
export const AuditLogApi = new _AuditLogApi();
export const WebsocketApi = new _WebsocketApi();
export const SendMessageApi = new _SendMessageApi();
